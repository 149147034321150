import React, { useState, createContext, useEffect, useContext } from "react";
import Loading from "../components/Loading";
import axios from "axios";

const TenantContext = createContext();

const TenantProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [tenantInfos, setTenantInfos] = useState([]);
  const [tenantBaseUrl, setTenantBaseUrl] = useState(null);

  const url = window.location.href;
  const tenantName = url.split(".")[0].split("//")[1];

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/GetTenantInfos`
        );
        const updatedTenants = data?.map((t) => ({
          domain: t.domain.split(".")[0],
          url: t.url,
        }));

        setTenantInfos(updatedTenants);
        if (updatedTenants.length > 0) {
          setTenantBaseUrl(updatedTenants[0].url);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const tenant = tenantInfos?.find((t) => t.domain === tenantName);
    if (tenantInfos?.length > 0) {
      if (tenant) {
        setTenantBaseUrl(tenant.url);
      } else {
        window.location.href = process.env.REACT_APP_NAVIGATE_URL;
      }
    }
  }, [tenantName, tenantInfos]);

  const values = {
    tenantInfos,
    tenantBaseUrl,
    setTenantBaseUrl,
  };

  if (loading) {
    return <Loading height="100vh" />;
  }

  return (
    <TenantContext.Provider value={values}>{children}</TenantContext.Provider>
  );
};

const useTenant = () => useContext(TenantContext);

export { TenantProvider, useTenant };
