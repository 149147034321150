import React, { useState, createContext, useContext } from "react";

const MenuContext = createContext();

const MenuProvider = ({ children }) => {
  const [categories, setCategories] = useState();
  const [products, setProducts] = useState();
  const [item, setItem] = useState();
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showKvkkModal, setShowKvkkModal] = useState(false);

  const values = {
    setCategories,
    categories,
    products,
    setProducts,
    item,
    setItem,
    showNotificationModal,
    setShowNotificationModal,
    showKvkkModal,
    setShowKvkkModal,
  };
  return <MenuContext.Provider value={values}>{children}</MenuContext.Provider>;
};

const useMenu = () => useContext(MenuContext);

export { MenuProvider, useMenu };
