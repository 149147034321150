import {
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

import { useMenu } from "../store/MenuContext";
import { Link } from "react-router-dom";

const KvkkModal = () => {
  const { showKvkkModal, setShowKvkkModal } = useMenu();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (showKvkkModal) {
      onOpen();
    }
  }, [showKvkkModal, onOpen]);

  const closeModal = () => {
    setShowKvkkModal(false);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalContent
          bottom={-10}
          position="fixed"
          width="100%"
          mx={20}
          mb={10}
          mt={10}
        >
          <ModalHeader textColor="teal">Çerez Politikası</ModalHeader>
          <ModalCloseButton color="teal" borderColor="teal" />
          <ModalBody>
            <Box
              display="flex"
              fontFamily="italic"
              textAlign="center"
              justifyContent="center"
              mt={5}
              textColor="gray"
            >
              <span>
                Size menü uygulamamızda gezinirken daha iyi bir deneyim sunmak
                ve pazarlama faaliyetlerimizde kullanmak için çerezler
                kullanmaktayız. Daha detaylı bilgi için:
                <Link
                  to="/privacy_policy"
                  style={{
                    color: "teal",
                    fontWeight: "semibold",
                    textDecoration: "underline",
                  }}
                >
                  Gizlilik Politikası
                </Link>
              </span>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={closeModal}>
              Kabul
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default KvkkModal;
