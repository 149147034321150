import React from "react";
import ReactDOM from "react-dom/client";
import "./css/reset.css";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { MenuProvider } from "./store/MenuContext";
import { BasketProvider } from "./store/BasketContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./store/AuthContext";
import customTheme from "./chakraui.config";
import { SettingsProvider } from "./store/SettingsContext";
import { TenantProvider } from "./store/TenantContext";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={customTheme}>
        <TenantProvider>
          <AuthProvider>
            <SettingsProvider>
              <BasketProvider>
                <MenuProvider>
                  <App />
                </MenuProvider>
              </BasketProvider>
            </SettingsProvider>
          </AuthProvider>
        </TenantProvider>
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
