import { Box, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../store/AuthContext";
import { useTranslation } from "react-i18next";
import { useSettings } from "../../store/SettingsContext";
const AccountBox = () => {
  const theme = useTheme();
  const { orderRequestStatus } = useSettings();
  const { loggedIn } = useAuth();
  const { t } = useTranslation();
  return (
    <>
      {loggedIn && orderRequestStatus ? (
        <Link to="/account">
          <Box
            height="140px"
            borderRadius={20}
            bgGradient={theme.colors.homeButtons}
          >
            <Box color="black" p="5" textAlign="center">
              <i className="fa-solid fa-wallet fa-2x"></i>
            </Box>
            <Box px="2">
              <Text
                fontSize="13px"
                color="black"
                fontWeight="bold"
                textAlign="center"
              >
                {t("Hesap Talebi")}
              </Text>
            </Box>
          </Box>
        </Link>
      ) : (
        <Box
          opacity="0.4"
          height="140px"
          borderRadius={20}
          bgGradient={theme.colors.homeButtons}
        >
          <Box color="black" p="5" textAlign="center">
            <i className="fa-solid fa-wallet fa-2x"></i>
          </Box>
          <Box px="2">
            <Text
              fontSize="13px"
              color="black"
              fontWeight="bold"
              textAlign="center"
            >
              {t("Hesap Talebi")}
            </Text>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AccountBox;
