import React, { useState, createContext, useContext, useEffect } from "react";

const BasketContext = createContext();

const BasketProvider = ({ children }) => {
  const [basket, setBasket] = useState([]);
  const [confirm, setConfirm] = useState([]);

  useEffect(() => {
    localStorage.setItem("basket", JSON.stringify(basket));
  }, [basket]);

  const addToBasket = (data, basketNote, checkedValues) => {
    const existingProduct = basket.find((item) => {
      return (
        item.id === data.id &&
        item.noteDetail === basketNote &&
        item.issuedContent === checkedValues?.toString()
      );
    });

    if (existingProduct) {
      setBasket(
        basket.map((item) => {
          if (
            item.id === data.id &&
            item.noteDetail === basketNote &&
            item.issuedContent === checkedValues?.toString()
          ) {
            return { ...item, count: item.count + 1 };
          } else {
            return item;
          }
        })
      );
    } else {
      setBasket([
        ...basket,
        {
          ...data,
          count: 1,
          noteDetail: basketNote,
          issuedContent: checkedValues?.toString(),
        },
      ]);
    }
  };

  const increase = (id, noteDetail, issuedContent) => {
    setBasket(
      basket.map((item) => {
        if (
          item.id === id &&
          item.noteDetail === noteDetail &&
          item.issuedContent === issuedContent
        ) {
          return { ...item, count: item.count + 1 };
        } else {
          return item;
        }
      })
    );
  };

  const decrease = (id, noteDetail, issuedContent) => {
    setBasket(
      basket
        .map((item) => {
          if (
            item.id === id &&
            item.noteDetail === noteDetail &&
            item.issuedContent === issuedContent
          ) {
            if (item.count > 1) {
              return { ...item, count: item.count - 1 };
            } else {
              return null;
            }
          } else {
            return item;
          }
        })
        .filter((item) => item !== null)
    );
  };

  const emptyBasket = () => setBasket([]);

  const values = {
    basket,
    setBasket,
    addToBasket,
    increase,
    decrease,
    emptyBasket,
    confirm,
    setConfirm,
  };

  return (
    <BasketContext.Provider value={values}>{children}</BasketContext.Provider>
  );
};

const useBasket = () => useContext(BasketContext);

export { BasketProvider, useBasket };
