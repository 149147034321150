export default {
  translation: {
    MENU: "MENÜ",
    LANG: "DİL",
    "Waiter Request": "Garson Talebi",
    "You can select and view the dish you want from the menu, create your cart and place your order.":
      "Menüden dilediğiniz yemeği seçip görüntüleyebilir, sepetinizi oluşturup siparişinizi verebilirsiniz.",
    "Song Request ": "İstek Şarkı",
    "Account Request": "Hesap Talebi",
    "QR Read": "QR Okut",
    "Order Status": "Sipariş Durumu",

    "Request Song": "İstek Şarkı Talebi",
    "Enter the name of the song you want":
      "İstediğiniz şarkının ismini giriniz",
    Close: "Kapat",
    "Send Request": "İsteği Gönder",
    Homepage: "Anasayfa",
    Menu: "Menü",
    Waiter: "Garson",
    Basket: "Sepet",
    Account: "Hesap",
    "Name :": "İsim :",
    Forwarded: "İletildi",
    Delivered: "Teslim Edildi",
    "Enter your name": "İsminizi giriniz",
    "Getting Ready": "Hazırlanıyor",
    "Do you have a note:": "Bir notunuz var mı:",
    "Enter your note": "Notunuzu Giriniz",
    "Dear customer, in order to call a waiter, you must scan the QR code.":
      "Değerli müşterimiz, garson çağırabilmek için QR kodu okutmalısınız.",
    "Call Waiter": "Garson Çağır",
    "Note :": "Not :",
    "Your waiter request has been received. It will be taken care of as soon as possible.":
      "Garson talebiniz alınmıştır. En kısa süre içerisinde ilgilenilecektir.",
    "Rate Us": "Bizi Değerlendirin",
    "For your suggestions and opinions:": "Öneri ve Görüşleriniz için:",
    "Your comments...": "Yorumlarınız...",
    Send: "Gönder",
    "Your suggestions and comments have been conveyed to the restaurant. We thank you...":
      "Öneri ve görüşleriniz restorana iletilmiştir. Teşekkür Ederiz...",
    Categories: "Kategoriler",
    "Search product...": "Ürün ara...",
    "Add to Basket": "Sepete Ekle",
    "See Detail": "Detayını Gör",
    "Allergen Information": "Alerjen Bilgileri",
    "Material Preference to be Extracted": "Çıkarılacak Malzeme Tercihi",
    "If you have a note you want to add:": "Eklemek istediğiniz bir not varsa:",
    "Add to Order List": "Sipariş Listesine Ekle",
    "My Order List": "Sipariş Listem",
    "There is no product in the order list":
      "Sipariş Listesinde Ürün Bulunmuyor",
    "To Order": "Sipariş Ver",
    "Your order has been received. We thank you...":
      "Siparişiniz Alınmıştır. Teşekkür ederiz...",
    "You can cancel your order within": "Siparişinizi",
    seconds: "saniye içinde iptal edebilirsiniz",
    "Cancel Order": "Sipariş İptal Et",
    "No Product Found": "Ürün Bulunmuyor",
    "Get Account": "Hesap Talep Et",
    "Account Amount :": "Hesap Tutarı :",
    Cash: "Nakit",
    "Credit Card": "Kredi Kartı",
    Mobile: "Mobil",
    "Payment method :": "Ödeme Şekli :",
    "Add Tip :": "Bahşiş Ekle :",
    "We thank you": "Teşekkür Ederiz",
    "Your Account Has Been Taken...": "Hesabınız Alınmıştır...",
    "About Menumate": "Menumate Hakkında",
    "Your Request Song Request Has Been Received...":
      "İstek Şarkı Talebiniz Alınmıştır...",
    "Out of stock": "Stokta Yok",
    "Favorite Products": "Favori Ürünlerimiz",
    "Find in Maps": "Haritalarda Bul",
  },
};
