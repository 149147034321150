export default {
  translation: {
    MENÜ: "MENU",
    DİL: "LANG",
    "Garson Talebi": "Waiter Request",
    "Menüden dilediğiniz yemeği seçip görüntüleyebilir, sepetinizi oluşturup siparişinizi verebilirsiniz.":
      "You can select and view the dish you want from the menu, create your cart and place your order.",
    "İstek Şarkı": "Song Request",
    "Hesap Talebi": "Account Request",
    "QR Okut": "QR Read",
    "Sipariş Durumu": "Order Status",
    "İstek Şarkı Talebi": "Request Song",
    "İstediğiniz şarkının ismini giriniz":
      "Enter the name of the song you want",
    Kapat: "Close",
    "İsteği Gönder": "Send Request",
    Anasayfa: "Homepage",
    Menü: "Menu",
    Garson: "Waiter",
    Sepet: "Basket",
    Hesap: "Account",
    "İsim :": "Name :",
    Hazırlanıyor: "Getting Ready",
    İletildi: "Forwarded",
    "Teslim Edildi": "Delivered",
    "İsminizi giriniz": "Enter your name",
    "Bir notunuz var mı:": "Do you have a note:",
    "Notunuzu Giriniz": "Enter your note",
    "Değerli müşterimiz, garson çağırabilmek için QR kodu okutmalısınız.":
      "Dear customer, in order to call a waiter, you must scan the QR code.",
    "Garson Çağır": "Call Waiter",
    "Not :": "Note :",
    "Garson talebiniz alınmıştır. En kısa süre içerisinde ilgilenilecektir.":
      "Your waiter request has been received. It will be taken care of as soon as possible.",
    "Bizi Değerlendirin": "Rate Us",
    "Öneri ve Görüşleriniz için:": "For your suggestions and opinions:",
    "Yorumlarınız...": "Your comments...",
    Gönder: "Send",
    "Öneri ve görüşleriniz restorana iletilmiştir. Teşekkür Ederiz...":
      "Your suggestions and comments have been conveyed to the restaurant. We thank you...",
    Kategoriler: "Categories",
    "Ürün ara...": "Search product...",
    "Sepete Ekle": "Add to Basket",
    "Detayını Gör": "See Detail",
    "Alerjen Bilgileri": "Allergen Information",
    "Çıkarılacak Malzeme Tercihi": "Material Preference to be Extracted",
    "Eklemek istediğiniz bir not varsa:": "If you have a note you want to add:",
    "Sipariş Listesine Ekle": "Add to Order List",
    "Sipariş Listem": "My Order List",
    "Sipariş Listesinde Ürün Bulunmuyor":
      "There is no product in the order list",
    "Sipariş Ver": "To Order",
    "Siparişiniz Alınmıştır. Teşekkür ederiz...":
      "Your order has been received. We thank you...",
    Siparişinizi: "You can cancel your order within",
    "saniye içinde iptal edebilirsiniz": "seconds",
    "Sipariş İptal Et": "Cancel Order",
    "Ürün Bulunmuyor": "No Product Found",
    "Hesap Talep Et": "Get Account",
    "Hesap Tutarı :": "Account Amount :",
    Nakit: "Cash",
    "Kredi Kartı": "Credit Card",
    Mobil: "Mobile",
    "Ödeme Şekli :": "Payment method :",
    "Bahşiş Ekle :": "Add Tip :",
    "Teşekkür Ederiz": "We thank you",
    "Hesabınız Alınmıştır...": "Your Account Has Been Taken...",
    "Menumate Hakkında": "About Menumate",
    "İstek Şarkı Talebiniz Alınmıştır...":
      "Your Request Song Request Has Been Received...",
    "Stokta Yok": "Out of stock",
    "Favori Ürünlerimiz": "Favorite Products",
    "Haritalarda Bul": "Find in Maps",
  },
};
