import { Box, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSettings } from "../../store/SettingsContext";

const InstagramBox = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { instagramUrl } = useSettings();

  return (
    <a href={instagramUrl}>
      <Box
        height="140px"
        borderRadius={20}
        bgGradient={theme.colors.homeButtons}
      >
        <Box color="black" p="5" textAlign="center">
          <i
            className="fa-brands fa-instagram"
            style={{ fontSize: "40px" }}
          ></i>
        </Box>
        <Box px="2">
          <Text
            fontSize="13px"
            color="black"
            fontWeight="bold"
            textAlign="center"
          >
            {t("Instagram")}
          </Text>
        </Box>
      </Box>
    </a>
  );
};

export default InstagramBox;
