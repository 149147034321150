import React, { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  useTheme,
} from "@chakra-ui/react";
import NavbarBottom from "../layout/NavbarBottom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../store/AuthContext";
import { useMenu } from "../store/MenuContext";
import KvkkModal from "../components/KvkkModal";
import { useSettings } from "../store/SettingsContext";
import {
  WaiterBox,
  RatingBox,
  InstagramBox,
  AccountBox,
  MapBox,
  AboutBox,
  WhatsappBox,
  SongRequestBox,
  OrderStatusBox,
  MenuAndLanguageBox,
} from "../components/boxes";
import HasBgPageNavbar from "../components/navbars/HasBgPageNavbar";

function HomePage() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { user, loggedIn } = useAuth();
  const { setShowKvkkModal } = useMenu();
  const {
    instagramStatus,
    songRequestStatus,
    whatsappStatus,
    waiterRequestStatus,
    accountRequestStatus,
    googleMapsStatus,
    orderStatus,
    reviewStatus,
  } = useSettings();

  const localStorageKvkk = localStorage.getItem("kvkk");

  useEffect(() => {
    if (localStorageKvkk === null) {
      setShowKvkkModal(true);
      localStorage.setItem("kvkk", true);
      localStorage.setItem("LANG", "tr-TR");
    }
  }, [setShowKvkkModal, localStorageKvkk]);

  return (
    <Box mb={15} bgGradient={theme.colors.homeBackground}>
      <Container>
        <HasBgPageNavbar />
        <Grid
          h="100%"
          templateRows="repeat(5, 1fr)"
          templateColumns="repeat(3, 1fr)"
          gap={5}
        >
          <GridItem rowSpan={1} colSpan={3}>
            <Box mt={5} color="white" textAlign="center">
              {t(
                "Menüden dilediğiniz yemeği seçip görüntüleyebilir, sepetinizi oluşturup siparişinizi verebilirsiniz."
              )}
            </Box>
          </GridItem>
          <GridItem rowSpan={1} colSpan={3}>
            <Box height="40%" mt={1}>
              {!loggedIn && (
                <Box height="70px" textAlign="center">
                  <Button colorScheme="whiteAlpha" color="white" width="100%">
                    Lütfen QR kodu okutunuz.
                  </Button>
                </Box>
              )}

              {loggedIn && (
                <>
                  <Box height="70px" textAlign="center">
                    <Button colorScheme="whiteAlpha" color="white" width="100%">
                      {user?.name}
                    </Button>
                  </Box>
                </>
              )}
            </Box>

            <MenuAndLanguageBox />
          </GridItem>

          {waiterRequestStatus && (
            <GridItem colSpan={1}>
              <WaiterBox />
            </GridItem>
          )}
          {accountRequestStatus && (
            <GridItem colSpan={1}>
              <AccountBox />
            </GridItem>
          )}
          {instagramStatus && (
            <GridItem colSpan={1}>
              <InstagramBox />
            </GridItem>
          )}
          {googleMapsStatus && (
            <GridItem colSpan={1}>
              <MapBox />
            </GridItem>
          )}
          {whatsappStatus && (
            <GridItem colSpan={1}>
              <WhatsappBox />
            </GridItem>
          )}
          {songRequestStatus && (
            <GridItem colSpan={1}>
              <SongRequestBox />
            </GridItem>
          )}
          {orderStatus && (
            <GridItem colSpan={1}>
              <OrderStatusBox />
            </GridItem>
          )}
          {reviewStatus && (
            <GridItem colSpan={1}>
              <RatingBox />
            </GridItem>
          )}

          <GridItem colSpan={1}>
            <AboutBox />
          </GridItem>
        </Grid>
      </Container>
      <KvkkModal />
      {loggedIn && <NavbarBottom />}
    </Box>
  );
}

export default HomePage;
