import { Box, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../store/AuthContext";
import { useTranslation } from "react-i18next";
import { useSettings } from "../../store/SettingsContext";
const WaiterBox = () => {
  const theme = useTheme();
  const { waiterRequestStatus } = useSettings();
  const { loggedIn } = useAuth();
  const { t } = useTranslation();
  return (
    <>
      {loggedIn && waiterRequestStatus ? (
        <Link to="/waiter_calling">
          <Box
            height="140px"
            borderRadius={20}
            p="5"
            bgGradient={theme.colors.homeButtons}
          >
            <Box color="black" textAlign="center">
              <i className="fa-solid fa-user-check fa-2x"></i>
            </Box>
            <Box mt={5}>
              <Text
                fontSize="13px"
                color="black"
                fontWeight="bold"
                textAlign="center"
              >
                {t("Garson Talebi")}
              </Text>
            </Box>
          </Box>
        </Link>
      ) : (
        <Box
          opacity="0.4"
          height="140px"
          borderRadius={20}
          p="5"
          bgGradient={theme.colors.homeButtons}
        >
          <Box color="black" textAlign="center">
            <i className="fa-solid fa-user-check fa-2x"></i>
          </Box>
          <Box mt={5}>
            <Text
              fontSize="13px"
              color="black"
              fontWeight="bold"
              textAlign="center"
            >
              {t("Garson Talebi")}
            </Text>
          </Box>
        </Box>
      )}
    </>
  );
};

export default WaiterBox;
