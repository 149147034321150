import { Box, Image } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useSettings } from "../../store/SettingsContext";
import DefaultLogo from "../../assets/menumate-short-logo.png";

const LogoContainer = ({
  bgType = "DEFAULT",
  bgRadius,
  customBgColor,
  roundLogo,
}) => {
  const { logo } = useSettings();
  const getBgColor = () => {
    switch (bgType) {
      case "BG_WHITE":
        return "white";
      case "BG_CUSTOM_COLOR":
        return customBgColor;
      case "DEFAULT":
        return "transparent";
    }
  };

  const getBorderRadius = () => {
    if (logo) {
      return bgRadius || null;
    } else {
      return "100%";
    }
  };

  const getRoundedLogo = () => {
    return roundLogo ? "full" : "none";
  };

  return (
    <Box>
      <Link to="/">
        <Box display="flex" justifyContent="center">
          <Box
            backgroundColor={logo ? getBgColor() : "white"}
            borderRadius={getBorderRadius}
            mt={4}
            p={5}
          >
            {logo ? (
              <Image
                height="120px"
                rounded={getRoundedLogo}
                src={`data:image/jpeg;base64,${logo}`}
                alt="logo"
              />
            ) : (
              <Image height="80px" src={DefaultLogo} alt="logo" />
            )}
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

export default LogoContainer;
