import axios from "axios";
import { useTenant } from "../store/TenantContext";

export const useFetchWrapper = () => {
  const { tenantBaseUrl } = useTenant();

  const getAuthHeaders = () => ({
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  });

  const fetchWrapper = {
    get: async (endpoint = "") => {
      const { data } = await axios.get(`${tenantBaseUrl}/api${endpoint}`);
      return data;
    },

    post: async (endpoint, postData, useAuth = true) => {
      const headers = useAuth ? getAuthHeaders() : {};
      const { data } = await axios.post(
        `${tenantBaseUrl}/api${endpoint}`,
        postData,
        {
          headers,
        }
      );
      return data;
    },
  };

  return fetchWrapper;
};
