import { Box, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";

const AboutBox = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      {/* <Link to="/about"> */}
      <Box
        opacity="0.3"
        height="140px"
        borderRadius={20}
        bgGradient={theme.colors.homeButtons}
      >
        <Box color="black" p="5" textAlign="center">
          <i className="fa-solid fa-circle-info fa-2x"></i>
        </Box>
        <Box px="2">
          <Text
            fontSize="13px"
            color="black"
            fontWeight="bold"
            textAlign="center"
          >
            {t("Menumate Hakkında")}
          </Text>
        </Box>
      </Box>
      {/* </Link> */}
    </>
  );
};

export default AboutBox;
