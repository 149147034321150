import { Box, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const OrderStatusBox = () => {
  const theme = useTheme();

  const { t } = useTranslation();
  return (
    <Link to="/order_status">
      <Box
        height="140px"
        borderRadius={20}
        p="5"
        bgGradient={theme.colors.homeButtons}
      >
        <Box color="black" textAlign="center">
          <i className="fa-solid fa-user-check fa-2x"></i>
        </Box>
        <Box mt={5}>
          <Text
            fontSize="13px"
            color="black"
            fontWeight="bold"
            textAlign="center"
          >
            {t("Sipariş Durumu")}
          </Text>
        </Box>
      </Box>
    </Link>
  );
};

export default OrderStatusBox;
