import {
  Alert,
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import NavbarBottom from "../layout/NavbarBottom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useApi } from "../api/api";
import Navbar from "../layout/Navbar";
import Loading from "../components/Loading";
import { useSettings } from "../store/SettingsContext";

function OrderStatus() {
  const { orderStatus } = useApi();

  const { t } = useTranslation();
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState(true);
  const { getLangText } = useSettings();
  useEffect(() => {
    (async () => {
      try {
        const data = await orderStatus();
        setProducts(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <Loading height="100vh" />;
  }

  return (
    <Box>
      <Navbar />

      <Link to="/basket">
        <Flex>
          <Box position="absolute" top="2" ml={2}>
            <i className="fas fa-angle-left yön"></i>
          </Box>
        </Flex>
      </Link>
      <Box mt={5} textAlign="center">
        <Text color="teal" fontSize={30} fontWeight="semibold">
          {t("Sipariş Durumu")}
        </Text>
      </Box>
      <br />

      <Box mb={90}>
        {products?.length < 1 && (
          <Container bgColor="white">
            <Alert status="warning">{t("Ürün Bulunmuyor")}</Alert>
          </Container>
        )}

        {products?.length > 0 && (
          <>
            {products?.map((product, i) => {
              return (
                <Box key={i} mb={3} bgColor="white">
                  <Container>
                    <Grid
                      h="100%"
                      templateColumns="repeat(5, 1fr)"
                      gap={2}
                      bgColor="gray.50"
                      borderRadius={12}
                      key={product.id}
                      boxShadow="2xl"
                    >
                      <GridItem w="100%" mt={1} p={2} colSpan={2}>
                        <Box fontSize={16} color="teal" fontWeight="bold">
                          {getLangText(product.title)}
                        </Box>
                      </GridItem>

                      <GridItem w="100%" colSpan={1} mt={1} p={2}>
                        <Box
                          border="1px"
                          borderRadius={8}
                          color="teal"
                          textAlign="center"
                          fontSize={16}
                        >
                          <Text paddingX={0} color="teal">
                            x<strong> {product.quantity}</strong>
                          </Text>
                        </Box>
                      </GridItem>
                      <GridItem w="100%" mt={1} p={2} colSpan={2}>
                        <Box
                          borderRadius={10}
                          border="1px solid teal"
                          mx="auto"
                          width="100px"
                          bgColor="white"
                          textAlign="center"
                          color="teal"
                          fontSize={16}
                        >
                          {product.status === "1" && t("Hazırlanıyor")}

                          {product.status === "0" && t("İletildi")}

                          {product.status === "2" && t("Teslim Edildi")}
                        </Box>
                      </GridItem>
                    </Grid>
                  </Container>
                </Box>
              );
            })}
          </>
        )}
      </Box>
      <NavbarBottom />
    </Box>
  );
}

export default OrderStatus;
