import { useFetchWrapper } from "./fetchWrapper";

export const useApi = () => {
  const fetchWrapper = useFetchWrapper();

  const fetchCategoryList = async () => {
    const response = await fetchWrapper.get("/category");
    return response?.categories;
  };

  const fetchCategory = async (categoryId) => {
    return await fetchWrapper.get(`/category/${categoryId}`);
  };

  const fetchProductList = async (categoryId) => {
    return await fetchWrapper.get(`/product/${categoryId}`);
  };

  const fetchFavoriteProducts = async () => {
    return await fetchWrapper.get("/favorite_products");
  };

  const fetchProductDetailPhotos = async (productId) => {
    return await fetchWrapper.get(`/images/${productId}`);
  };

  const fetchProductDetails = async (productId) => {
    return await fetchWrapper.get(`/product_details/${productId}`);
  };

  const fetchSong = async () => {
    return await fetchWrapper.get("/songdetail");
  };

  const fetchLogin = async (input) => {
    return await fetchWrapper.post("/login", input);
  };

  const fetchMe = async () => {
    const data = await fetchWrapper.post("/me", {
      _token: localStorage.getItem("access-token"),
    });
    return data;
  };

  const fetchLogout = async () => {
    return await fetchWrapper.post("/logout", {});
  };

  const postOrder = async (input) => {
    return await fetchWrapper.post("/order", input);
  };

  const postSongStatus = async (input) => {
    return await fetchWrapper.post("/songStatusUpdate", { song_status: input });
  };

  const postWaiter = async (input) => {
    return await fetchWrapper.post("/waiter", input);
  };

  const postSong = async (input) => {
    return await fetchWrapper.post("/song", input);
  };

  const postReview = async (input) => {
    return await fetchWrapper.post("/review", input);
  };

  const accoundShow = async () => {
    return await fetchWrapper.post("/account", {
      _token: localStorage.getItem("access-token"),
    });
  };

  const orderStatus = async () => {
    return await fetchWrapper.post("/order_status", {
      _token: localStorage.getItem("access-token"),
    });
  };

  const accountRequest = async (input) => {
    return await fetchWrapper.post("/account_request", input);
  };

  const settings = async () => {
    return await fetchWrapper.get("/GetSettings");
  };

  return {
    fetchCategoryList,
    fetchCategory,
    fetchProductList,
    fetchFavoriteProducts,
    fetchProductDetailPhotos,
    fetchProductDetails,
    fetchSong,
    fetchLogin,
    fetchMe,
    fetchLogout,
    postOrder,
    postSongStatus,
    postWaiter,
    postSong,
    postReview,
    accoundShow,
    orderStatus,
    accountRequest,
    settings,
  };
};
