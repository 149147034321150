import { Box, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { useSettings } from "../../store/SettingsContext";
import { useTranslation } from "react-i18next";

const MapBox = () => {
  const theme = useTheme();
  const { mapUrl } = useSettings();
  const { t } = useTranslation();
  return (
    <>
      {/* {loggedIn ? (
              <SongRequest
                logged={true}
                songRequestStatus={songRequestStatus}
              />
            ) : (
              <SongRequest logged={false} />
            )} */}
      <a href={mapUrl}>
        <Box
          height="140px"
          borderRadius={20}
          bgGradient={theme.colors.homeButtons}
        >
          <Box color="black" p="5" textAlign="center">
            <i
              className="fa-solid fa-map-location-dot"
              style={{ fontSize: "40px" }}
            ></i>
          </Box>
          <Box px="2">
            <Text
              fontSize="13px"
              color="black"
              fontWeight="bold"
              textAlign="center"
            >
              {t("Haritalarda Bul")}
            </Text>
          </Box>
        </Box>
      </a>
    </>
  );
};

export default MapBox;
