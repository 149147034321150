import React from "react";
import LogoContainer from "../logo/LogoContainer";

const HasBgPageNavbar = () => {
  return (
    <LogoContainer
      radius="50%"
      customBgColor="white"
      roundLogo
      //   bgType="BG_CUSTOM_COLOR"
    />
  );
};

export default HasBgPageNavbar;
