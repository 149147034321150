import {
  Box,
  Button,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../api/api";

const SongRequestBox = () => {
  const { postSong } = useApi();

  const theme = useTheme();
  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="4px"
    />
  );

  const [name, setName] = useState("");
  const [song, setSong] = useState("");
  const [overlay, setOverlay] = useState(<OverlayTwo />);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleChangeSong = (e) => {
    setSong(e.target.value);
  };

  const openModal = () => {
    setOverlay(<OverlayTwo />);
    onOpen();
  };

  const songRequest = async () => {
    const data = {
      name,
      note: song,
    };
    await postSong(data);
    setName("");
    setSong("");
  };

  return (
    <div>
      <Box
        onClick={() => openModal()}
        height="140px"
        borderRadius={20}
        p="5"
        bgGradient={theme.colors.homeButtons}
      >
        <Box color="black" textAlign="center">
          <i className="fa-solid fa-music fa-2x"></i>
        </Box>
        <Box mt={5}>
          <Text
            fontSize="13px"
            color="black"
            fontWeight="bold"
            textAlign="center"
          >
            {t("İstek Şarkı")}
          </Text>
        </Box>

        <Modal isCentered isOpen={isOpen} onClose={onClose}>
          {overlay}
          <ModalContent>
            <ModalHeader>{t("İstek Şarkı Talebi")}</ModalHeader>
            <ModalBody>
              <FormControl mt={4}>
                <Input
                  value={name}
                  onChange={handleChangeName}
                  placeholder={t("İsminizi giriniz")}
                />
              </FormControl>
              <FormControl mt={4}>
                <Input
                  value={song}
                  onChange={handleChangeSong}
                  placeholder={t("İstediğiniz şarkının ismini giriniz")}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose} mr={3}>
                {t("Kapat")}
              </Button>
              <Button
                onClick={songRequest}
                color="white"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                bgGradient={theme.colors.ithinkGradient}
                _hover={{ bgColor: theme.colors.ithinkGradient }}
                sbackground={theme.colors.ithinkGradient}
              >
                {t("İsteği Gönder")}
              </Button>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">
                        {t("Teşekkür Ederiz")}
                      </h1>
                    </div>
                    <div className="modal-body">
                      {t("İstek Şarkı Talebiniz Alınmıştır...")}
                    </div>
                    <div className="modal-footer">
                      <button
                        onClick={onClose}
                        type="button"
                        className="btn btn-success"
                        data-bs-dismiss="modal"
                      >
                        {t("Kapat")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </div>
  );
};

export default SongRequestBox;
