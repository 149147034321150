import React from "react";
import LogoContainer from "../logo/LogoContainer";

const BgPageWhiteNavbar = () => {
  return (
    <LogoContainer bgRadius="50%" logo customBgColor="green" bgType="DEFAULT" />
  );
};

export default BgPageWhiteNavbar;
