import { Box } from "@chakra-ui/react";
import React from "react";
import BgPageWhiteNavbar from "../components/navbars/BgPageWhiteNavbar";

function Navbar() {
  return (
    <Box mt={5}>
      <BgPageWhiteNavbar />
    </Box>
  );
}

export default Navbar;
