import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { useApi } from "../api/api";
import RequestSongSwitch from "../components/RequestSongSwitch";
import { Alert, Box, CloseButton, Container, Text } from "@chakra-ui/react";
import BgPageWhiteNavbar from "../components/navbars/BgPageWhiteNavbar";
import { useTenant } from "../store/TenantContext";

function RequestSongFollow() {
  const { fetchSong } = useApi();

  const { tenantBaseUrl } = useTenant();

  const [loading, setLoading] = useState(true);
  const [requestSongs, setRequestSongs] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchSong();
        setRequestSongs(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 30 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return <Loading height="100vh" />;
  }

  const removeSong = async (id) => {
    try {
      await fetch(`${tenantBaseUrl}/api/update_song_status/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          new_status: 1,
        }),
      });

      setRequestSongs((prevSongs) => {
        const updatedSongs = prevSongs.map((song) => {
          if (song.id === id) {
            return { ...song, status: 1 };
          }
          return song;
        });
        return updatedSongs.filter((song) => song.status !== 1);
      });
    } catch (error) {
      console.error("Error updating song status:", error);
    }
  };

  return (
    <Box>
      <Container>
        <BgPageWhiteNavbar />
        <RequestSongSwitch />
        <Text
          mt={6}
          fontWeight="semibold"
          textAlign="center"
          fontSize={30}
          color="teal.500"
          mb="15px"
        >
          İstek Şarkı Takip
        </Text>
      </Container>
      <Container bgColor="white">
        {requestSongs &&
        requestSongs.some((song) => song.status === 0 && song.note !== null) ? (
          <>
            {requestSongs
              .filter((song) => song.status === 0 && song.note !== null)
              .reverse()
              .map((song) => (
                <Alert
                  key={song.id}
                  status="info"
                  mb={2}
                  borderRadius="20px"
                  d="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <CloseButton
                    size="sm"
                    position="absolute"
                    right="3"
                    top="2"
                    onClick={() => removeSong(song.id)}
                    borderRadius="50%"
                    bgColor="white"
                    color="black"
                  />

                  <Box>
                    <strong>Masa Numarası:</strong> {song.user_name}
                  </Box>
                  <Box>
                    <strong>Müşteri Adı:</strong>{" "}
                    {song.song_name !== null ? song.song_name : ""}
                  </Box>
                  <Box>
                    <strong>İstek Şarkı:</strong> {song.note}
                  </Box>
                </Alert>
              ))}
          </>
        ) : (
          <Alert status="warning">Şarkı İsteği Bulunmuyor</Alert>
        )}
      </Container>
    </Box>
  );
}

export default RequestSongFollow;
