import { Box, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSettings } from "../../store/SettingsContext";

const WhatsappBox = () => {
  const theme = useTheme();
  const { whatsappPhone } = useSettings();

  const { t } = useTranslation();
  return (
    <a href={`https://api.whatsapp.com/send/?phone=90${whatsappPhone}`}>
      <Box
        height="140px"
        borderRadius={20}
        bgGradient={theme.colors.homeButtons}
      >
        <Box color="black" p="5" textAlign="center">
          <i className="fa-brands fa-whatsapp" style={{ fontSize: "40px" }}></i>
        </Box>
        <Box px="2">
          <Text
            fontSize="13px"
            color="black"
            fontWeight="bold"
            textAlign="center"
          >
            {t("Whatsapp")}
          </Text>
        </Box>
      </Box>
    </a>
  );
};

export default WhatsappBox;
