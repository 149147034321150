import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CheckboxGroup,
  Checkbox,
  Textarea,
  Flex,
  Container,
  Grid,
  GridItem,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { useBasket } from "../store/BasketContext";
import { useMenu } from "../store/MenuContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Carousel from "react-bootstrap/Carousel";
import { MinusIcon } from "@chakra-ui/icons";
import NavbarBottom from "../layout/NavbarBottom";
import { useQuery } from "react-query";
import { useApi } from "../api/api";
import { useTranslation } from "react-i18next";

import Navbar from "../layout/Navbar";
import Loading from "../components/Loading";
import { useSettings } from "../store/SettingsContext";
import { useTenant } from "../store/TenantContext";

function ProductDetail() {
  const { fetchProductDetailPhotos, fetchProductDetails } = useApi();

  const { tenantBaseUrl } = useTenant();

  const theme = useTheme();
  const { productId } = useParams();
  const { addToBasket } = useBasket();
  const { t } = useTranslation();
  const { item, setItem } = useMenu();
  const [basketNote, setBasketNote] = useState("");
  const [checkedValues, setCheckedValues] = useState([]);
  const { getLangText } = useSettings();

  useEffect(() => {
    (async () => {
      try {
        const detail = await fetchProductDetails(productId);
        setItem(detail);
      } catch (e) {}
    })();
  }, [productId, setItem]);

  const handleChangeBasketNote = (e) => {
    setBasketNote(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCheckedValues((pre) => [...pre, value]);
    } else {
      setCheckedValues((pre) => {
        return [...pre.filter((material) => material !== value)];
      });
    }
  };

  const notify = () =>
    toast("Ürün Sepete Eklendi!", {
      hideProgressBar: true,
      position: "top-left",
    });

  const { isLoading, error, data } = useQuery(["images", productId], () =>
    fetchProductDetailPhotos(productId)
  );
  if (isLoading) return <Loading height="100vh" />;

  let material = [];
  if (getLangText(item?.removed_material) !== null) {
    material = getLangText(item?.removed_material)?.split(",");
  } else {
    material = [];
  }

  const addBasketAll = (item, basketNote, checkedValues) => {
    addToBasket(item, basketNote, checkedValues);
    notify();
  };

  return (
    <>
      <Navbar />

      <Box mt={5}>
        <Link to={`/product/${item?.category_id}`}>
          <Flex>
            <Box position="absolute" top="2" ml={2}>
              <i className="fas fa-angle-left yön"></i>
            </Box>
          </Flex>
        </Link>

        <Box mt={3} bgColor="white">
          <Box mt={2}>
            <Grid
              h="100%"
              gridTemplateRows="auto auto auto auto auto auto "
              gridTemplateColumns="repeat(1, 1fr)"
              gap={4}
            >
              <GridItem rowSpan={2} colSpan={2}>
                <Container>
                  <Box
                    mt="1"
                    mb={5}
                    textAlign="center"
                    fontSize={27}
                    fontWeight="bold"
                    as="h1"
                    lineHeight="tight"
                    borderBottom="1px"
                  >
                    {getLangText(item?.title)}
                  </Box>
                </Container>
                <Container>
                  {item?.image !== null && (
                    <Box>
                      <Carousel interval={2000}>
                        <Carousel.Item>
                          <img
                            className="d-block"
                            src={`${tenantBaseUrl}/storage/${item?.image}`}
                            alt={getLangText(item?.title)}
                            style={{ width: "450px", height: "250px" }}
                          />
                        </Carousel.Item>
                        {!error &&
                          data?.map((item, index) => {
                            return (
                              <Carousel.Item key={index}>
                                <img
                                  key={index}
                                  className="d-block "
                                  src={`${tenantBaseUrl}/storage/${item?.image}`}
                                  alt={getLangText(item?.title)}
                                  style={{ width: "450px", height: "250px" }}
                                />
                              </Carousel.Item>
                            );
                          })}
                      </Carousel>
                    </Box>
                  )}
                </Container>
                {getLangText(item?.description) !== "" && (
                  <Container>
                    <Box fontWeight="bold" textAlign="left" color="gray" p="4">
                      {getLangText(item?.description)}
                    </Box>
                  </Container>
                )}

                {getLangText(item?.allergen) !== null && (
                  <Box>
                    <Box
                      boxShadow="md"
                      bgColor="gray.100"
                      p="3"
                      w="100%"
                      my="2"
                      fontSize={15}
                      fontWeight="semibold"
                      as="h5"
                      lineHeight="tight"
                    >
                      <Container color="gray">
                        {t("Alerjen Bilgileri")}
                      </Container>
                    </Box>
                    <Container>
                      <Box color="gray" p="4">
                        {getLangText(item?.allergen)}
                      </Box>
                    </Container>
                  </Box>
                )}

                {material?.length > 0 && (
                  <Box>
                    {material?.length > 0 ? (
                      <Box
                        boxShadow="md"
                        bgColor="gray.100"
                        p="3"
                        w="100%"
                        my="2"
                        fontSize={15}
                        fontWeight="semibold"
                        as="h5"
                        lineHeight="tight"
                      >
                        <Container color="gray">
                          <Text color="gray">
                            {t("Çıkarılacak Malzeme Tercihi")}
                          </Text>
                        </Container>
                      </Box>
                    ) : (
                      ""
                    )}
                    <Container>
                      <Box padding="3">
                        <CheckboxGroup mt="5">
                          <Box spacing="12px" direction={"['column', 'row']"}>
                            {material?.map((content, key) => {
                              return (
                                <Checkbox
                                  value={content}
                                  mr={2}
                                  icon={<MinusIcon />}
                                  borderColor="teal"
                                  colorScheme="red"
                                  key={key}
                                  color="gray"
                                  name={content}
                                  onChange={handleCheckboxChange}
                                >
                                  {content}
                                </Checkbox>
                              );
                            })}
                          </Box>
                        </CheckboxGroup>
                      </Box>
                    </Container>
                  </Box>
                )}
                <Box
                  boxShadow="md"
                  bgColor="gray.100"
                  p="3"
                  w="100%"
                  my="2"
                  fontSize={15}
                  fontWeight="semibold"
                  as="h5"
                  lineHeight="tight"
                >
                  <Container color="gray">
                    {t("Eklemek istediğiniz bir not varsa:")}
                  </Container>
                </Box>
                <Container>
                  <Textarea
                    height="150px"
                    value={basketNote}
                    onChange={handleChangeBasketNote}
                    color="teal"
                    mt="3"
                  />
                </Container>

                <Box mt={3} width="100%" bgColor="white" boxShadow="lg">
                  <Container>
                    {item?.status ? (
                      <Button
                        onClick={() =>
                          addBasketAll(item, basketNote, checkedValues)
                        }
                        height="50px"
                        width="65%"
                        borderRadius={false}
                        borderLeftRadius={12}
                        color="white"
                        bgGradient={theme.colors.ithinkGradient}
                        _hover={{ bgColor: theme.colors.ithinkGradient }}
                        background={theme.colors.ithinkGradient}
                      >
                        {t("Sipariş Listesine Ekle")}
                      </Button>
                    ) : (
                      <Button
                        isDisabled={true}
                        onClick={() =>
                          addBasketAll(item, basketNote, checkedValues)
                        }
                        height="50px"
                        width="65%"
                        borderRadius={false}
                        borderLeftRadius={12}
                        color="white"
                        bgGradient={theme.colors.ithinkGradient}
                        _hover={{ bgColor: theme.colors.ithinkGradient }}
                        background={theme.colors.ithinkGradient}
                      >
                        {t("Sipariş Listesine Ekle")}
                      </Button>
                    )}

                    <Button
                      fontSize="lg"
                      fontWeight="bold"
                      height="50px"
                      boxShadow="lg"
                      width="35%"
                      borderRadius={false}
                      borderRightRadius={12}
                      color="teal"
                      colorScheme="white"
                    >
                      {item?.price} ₺
                    </Button>
                  </Container>
                </Box>
              </GridItem>
            </Grid>
          </Box>

          <ToastContainer
            newestOnTop={true}
            theme="light"
            position="top-center"
            autoClose={1000}
          />
        </Box>
        <NavbarBottom />
      </Box>
    </>
  );
}

export default ProductDetail;
